import {Controller, useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import {Grid, Option, Select} from "@mui/joy";
import DomainAutocomplete from "../../../../components/domain/DomainAutocomplete";
import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import {useSWRConfig} from "swr";

export default function CourseFilters() {
    const {handleSubmit, control, reset} = useForm({mode: 'all'});
    const {mutate} = useSWRConfig();
    const [searchParams, setSearchParams] = useSearchParams();
    const institutionId = searchParams.getAll("institutionId");
    const jobTitleId = searchParams.getAll("jobTitleId");
    const name = searchParams.get("name");
    const type = searchParams.get("type");
    const published = searchParams.get("published");

    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSearchParams({
            name: data.name,
            type: data.type || '',
            institutionId: data.institutionId.map(x => x.id),
            jobTitleId: data.jobTitleId.map(x => x.id),
            published: data.published
        });
        mutate((key) => typeof key === 'string' && key.startsWith(`/course`));
    }
    return (
        <Box
            className="SearchAndFilters-tabletUp"
            sx={{
                borderRadius: 'sm',
                py: 2,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1.5, '& > *': {
                    minWidth: {xs: '120px', md: '160px'},
                },
            }}
        >
            <form style={{width: "100%"}} onSubmit={handleSubmit(onSubmit)}>
                <Grid sx={{width: 1}} container spacing={1}>
                    <Grid xl={4} xs={12}>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue={name || ''}
                            render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}}>
                                <FormLabel>Nome</FormLabel>
                                <Input type="text" {...field}/>
                            </FormControl>}
                        />
                    </Grid>
                    <Grid xl={2} lg={3} md={4} sm={6} xs={12}>
                        <DomainAutocomplete control={control}
                                            domain="institution"
                                            defaultValue={institutionId}
                                            name="institutionId"
                                            title="Instituição"
                        />
                    </Grid>
                    <Grid xl={2} lg={3} md={4} sm={6} xs={12}>
                        <DomainAutocomplete control={control}
                                            domain="job-title"
                                            defaultValue={jobTitleId}
                                            name="jobTitleId"
                                            title="Cargo"/>
                    </Grid>
                    <Grid xl={2} lg={3} sm={6} xs={12}>
                        <Controller
                            name="type"
                            defaultValue={type}
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <FormControl size="sm">
                                        <FormLabel>Tipo</FormLabel>
                                        <Select onChange={(event, newValue) => onChange(newValue)}
                                                value={value}>
                                            <Option value="PRE_EDITAL">Pré Edital</Option>
                                            <Option value="POS_EDITAL">Pós Edital</Option>
                                            <Option value="ISOLADAS">Isoladas</Option>
                                            <Option value="DETONANDO">Detonando</Option>
                                        </Select>
                                    </FormControl>
                                )
                            }}
                        />
                    </Grid>
                    <Grid xl={2} lg={3} sm={6} xs={12}>
                        <Controller
                            name="published"
                            defaultValue={published}
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <FormControl size="sm">
                                        <FormLabel>Publicado</FormLabel>
                                        <Select onChange={(event, newValue) => onChange(newValue)}
                                                value={value}>
                                            <Option value="true">Sim</Option>
                                            <Option value="false">Não</Option>
                                        </Select>
                                    </FormControl>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{
                    width: 1, px: 1,
                    display: "flex",
                    justifyContent: "end",
                    gap: 1
                }}>
                    <Button sx={{mt: 2, width: 100}} type="button" variant="soft" size="sm" color="primary"
                            onClick={() => {
                                setSearchParams({});
                                reset();
                            }}>
                        Limpar
                    </Button>
                    <Button sx={{mt: 2, width: 100}} type="submit" size="sm" color="primary">
                        Filtrar
                    </Button>
                </Box>
            </form>
        </Box>
    );
}
