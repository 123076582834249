import Button from "@mui/joy/Button";
import {Favorite} from "@mui/icons-material";
import * as React from "react";
import axios from "axios";
import {useContext, useState} from "react";
import {UserContext} from "../contexts/UserContext";
import {containsAuthority} from "../utils";

export default function FavButton({course}) {
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const user = useContext(UserContext);

    if (!containsAuthority('WRITE_FAVORITE', user)) {
        return undefined;
    }

    const handleClick = () => {
        setSubmitting(true);
        setSubmitError(null);
        const url = `course/${course.id}/user`;
        (course.favorite ? axios.delete(url) : axios.put(url))
            .then(response => {
                course.favorite = !course.favorite;
            })
            .catch(error => {
                setSubmitError(error);
            })
            .finally(() => setSubmitting(false));
    }

    return (
        <Button variant='plain'
                color='neutral'
                onClick={handleClick}
                loading={submitting}
                startDecorator={<Favorite sx={{color: course.favorite ? '#f1c40f' : 'neutral'}}/>}
        >
            {course.favorite ? 'Favorito' : 'Favoritar'}
        </Button>
    )
}