import * as React from "react";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {AspectRatio, styled, useTheme} from "@mui/joy";
import {blobToBase64} from "../../../utils";
import ImageIcon from '@mui/icons-material/Image';
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {useMediaQuery} from "@mui/material";
import PageTitle from "../../PageTitle";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function InstitutionBanner({institution, edit = false, fallback = true}) {
    const [banner, setBanner] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const fileInput = useRef(null)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));


    const getPicture = () => {
        axios({
            url: `/institution/${institution.id}/banner`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            blobToBase64(response.data).then(pic => {
                setBanner(pic);
            });
        }).catch(error => {
        });
    }

    useEffect(() => {
        getPicture();
    }, []);

    if (!institution) {
        return undefined;
    }

    const handleChange = (event) => {
        setSubmitting(true)
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        axios.put(`institution/${institution.id}/banner`,
            formData, {
                headers: {"Content-Type": "multipart/form-data"}
            }).then(reponse => {
            getPicture();
            setSubmitting(false);
        })
    }

    if (!fallback && !banner) {
        return undefined;
    }

    const maxHeight = matches ? 250 : 120;
    const level = matches ? 'h1' : 'h3';

    if (edit) {
        return (<>
            <VisuallyHiddenInput ref={fileInput}
                                 type="file"
                                 accept="image/*"
                                 onChange={handleChange}/>
            <Box sx={{cursor: 'pointer', width: 1}} onClick={() => {
                fileInput.current?.click();
            }}> {banner ?
                <AspectRatio variant='plain' minHeight={60} maxHeight={maxHeight}>
                    <img
                        src={`data:image/jpeg;base64, ${banner}`}
                        alt="Banner"
                    />
                </AspectRatio> : <ImageIcon sx={{fontSize: '3rem', opacity: 0.2}}/>
            }
            </Box>
        </>)
    } else if (banner) {
        return (
            <div className="hero-image"
                 style={{backgroundImage: `url("data:image/jpeg;base64, ${banner}")`, height: maxHeight}}>
                <div className="hero-text">
                    <Typography sx={{color: 'white'}} level={level} component="div">
                        {institution.name}
                    </Typography>
                </div>
            </div>
        )
    } else {
        return (
            <PageTitle>
                {institution.name}
            </PageTitle>
        );
    }
}