import * as React from "react";
import {Table} from "@mui/joy";
import SectionRow from "./SectionRow";
import {useState} from "react";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import axios from "axios";
import {mutate} from "swr";
import {useSearchParams} from "react-router-dom";

export default function SectionTableContent({courseId, moduleId, sections = [], onError}) {
    if (!moduleId) {
        throw new Error("moduleId is required");
    }
    const [searchParams] = useSearchParams();
    const selectedSection = parseInt(searchParams.get('sectionId')) || undefined;

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(sections);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        for (let i = 0; i < tempData.length; i++) {
            tempData[i].position = i + 1;
        }
        // setSections(tempData);
        mutate(`/module/${moduleId}/section`, async sections => {
            await axios.patch(`module/${moduleId}/section/position`, tempData.map(m => m.id))
            return tempData;
        }, {
            revalidate: false, optimisticData: tempData, rollbackOnError(error) {
                onError(error);
                return true;
            }
        });
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Table
                size="sm"
                aria-label="collapsible table"
                sx={{
                    '& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]':
                        {
                            borderBottom: 0,
                        },
                }}
            >
                <thead>
                <tr>
                    <th style={{width: 80}} aria-label="empty"/>
                    <th style={{width: 120}}>Posição</th>
                    <th style={{width: '100%'}}>Nome</th>
                    <th style={{width: 80}}></th>
                </tr>
                </thead>
                <Droppable droppableId="droppable-2">
                    {(provider) => (
                        <tbody ref={provider.innerRef} {...provider.droppableProps}>
                        {sections.map((section, index) => (
                            <Draggable key={section.id} draggableId={section.id.toString()} index={index}>
                                {(provider) => (
                                    <SectionRow key={section.id}
                                                courseId={courseId}
                                                moduleId={moduleId}
                                                section={section}
                                                provider={provider}
                                                initialOpen={selectedSection ? index === sections.findIndex(s => s.id === selectedSection) : index === 0}
                                    />
                                )}
                            </Draggable>
                        ))}
                        </tbody>
                    )}
                </Droppable>
            </Table>
        </DragDropContext>
    )
}
