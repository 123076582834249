import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import Stack from "@mui/joy/Stack";
import {FormHelperText} from "@mui/joy";
import Button from "@mui/joy/Button";
import axios from "axios";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import * as React from "react";
import {mutate} from "swr";
import ErrorAlert from "../../ErrorAlert";
import DomainSelect from "../DomainSelect";

export default function InstitutionForm({institution, onClose}) {
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const {handleSubmit, control, setValue, formState: {errors}} = useForm({mode: 'all'});

    const handleSubmitWithoutPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
    };

    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSubmiting(true);
        setSubmitError(undefined);
        (institution ? axios.put(`institution/${institution.id}`, data) : axios.post('institution', data))
            .then(response => {
                return mutate((key) => typeof key === 'string' && key.startsWith(`/institution`), undefined,
                    {revalidate: true})
                    .then(() => onClose(response.data));
            })
            .catch(error => {
                setSubmitError(error);
            })
            .finally(() => setSubmiting(false));
    }

    return (
        <>
            <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
            <form onSubmit={handleSubmitWithoutPropagation}>
                <Stack gap={2}>
                    <DomainSelect control={control}
                                  setValue={setValue}
                                  errors={errors}
                                  defaultValue={institution?.careers}
                                  domain="career"
                                  multiple={true}
                                  name="careers"
                                  canAdd={false}
                                  mandatory={false}
                                  fullwidth={true}
                                  title="Carreiras"/>
                    <DomainSelect control={control}
                                  setValue={setValue}
                                  errors={errors}
                                  defaultValue={institution?.states}
                                  domain="state"
                                  multiple={true}
                                  name="states"
                                  canAdd={false}
                                  mandatory={false}
                                  fullwidth={true}
                                  title="Estados"/>
                    <Controller
                        name="name"
                        defaultValue={institution?.name || ""}
                        control={control}
                        rules={{required: 'Campo obrigatório'}}
                        helperText={errors.name?.message}
                        render={({field}) =>
                            <FormControl size="sm" error={!!errors.name}>
                                <FormLabel>Nome</FormLabel>
                                <Input {...field}/>
                                <FormHelperText>{errors.name?.message}</FormHelperText>
                            </FormControl>
                        }
                    />
                    <Button loading={submiting} type="submit">Salvar</Button>
                </Stack>
            </form>
        </>
    );
}
