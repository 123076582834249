import {useState} from "react";
import axios from "axios";
import {mutate} from "swr";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import LectureSelect from "../../../../../components/lecture/LectureSelect";
import ErrorAlert from "../../../../../components/ErrorAlert";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import {Alert, LinearProgress, Table} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import * as React from "react";
import SectionLectureMenu from "./SectionLectureMenu";
import Button from "@mui/joy/Button";
import {useNavigate} from "react-router-dom";

export default function LectureSectionTable({courseId, moduleId, sectionId, lectureSectionDefault = []}) {
    const [lectureSection, setLectureSection] = useState(lectureSectionDefault)
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const navigate = useNavigate();

    const handleCreateLecture = () => {
        navigate(`/registration/lecture/new?courseId=${courseId}&moduleId=${moduleId}&sectionId=${sectionId}`);
    }

    const handleAddLecture = (lecture) => {
        if (!lecture) {
            return;
        }
        setSubmitting(true);
        setSubmitError(undefined);
        axios.patch(`section/${sectionId}/lecture/${lecture.id}`)
             .then(response => {
                 setLectureSection(response.data);
                 return mutate((key) => typeof key === 'string' && key.startsWith(`/module/${moduleId}/section`));
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmitting(false));
    }

    const handleRemoveLecture = (lecture) => {
        setSubmitting(true);
        setSubmitError(undefined);
        axios.delete(`section/${sectionId}/lecture/${lecture.id}`)
             .then(response => {
                 setLectureSection(response.data);
                 return mutate((key) => typeof key === 'string' && key.startsWith(`/module/${moduleId}/section`));
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmitting(false));
    }

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let backup = JSON.parse(JSON.stringify(lectureSection));
        let tempData = Array.from(lectureSection);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        for (let i = 0; i < tempData.length; i++) {
            tempData[i].position = i + 1;
        }
        setLectureSection(tempData);
        axios.patch(`section/${sectionId}/lecture/position`, tempData.map(ls => ls.lecture.id))
             .then(() => {
                 return mutate((key) => typeof key === 'string' && key.startsWith(`/module/${moduleId}/section`));
             })
             .catch(error => {
                 setLectureSection(backup);
                 setSubmitError(error);
             });
    };

    return (
        <Sheet
            variant="soft"
            sx={{p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)'}}
        >
            <Stack spacing={1}>
                <Stack justifyContent="space-between" direction="row">
                    <Typography level="body-lg" component="div">
                        Selecionar Aulas
                    </Typography>
                    <Button variant="solid" onClick={handleCreateLecture}>Cadastrar nova aula</Button>
                </Stack>
                <LectureSelect onChange={handleAddLecture} selectedLectures={lectureSection.map(ls => ls.lecture.id)}/>
                <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                {submitting ? <LinearProgress/> : undefined}
                {lectureSection.length > 0 ? <DragDropContext onDragEnd={handleDragEnd}>
                    <Table
                        borderAxis="bothBetween"
                        size="sm"
                        aria-label="purchases"
                        sx={{
                            '& > thead > tr > th:nth-child(n + 4), & > tbody > tr > td:nth-child(n + 4)':
                                {textAlign: 'right'},
                            '--TableCell-paddingX': '0.5rem',
                        }}
                    >
                        <thead>
                        <tr>
                            <th style={{width: 60}}>Posição</th>
                            <th>Nome</th>
                            <th style={{width: '25%'}}>Professor</th>
                            <th style={{width: 60}}></th>
                        </tr>
                        </thead>
                        <Droppable droppableId="droppable-3">
                            {(provider) => (
                                <tbody ref={provider.innerRef} {...provider.droppableProps}>
                                {lectureSection.map((lectureSection, index) => (
                                    <Draggable key={lectureSection.lecture.id}
                                               draggableId={lectureSection.lecture.id.toString()}
                                               index={index}>
                                        {(provider) => (
                                            <tr key={lectureSection.lecture.id}
                                                {...provider.draggableProps}
                                                ref={provider.innerRef}>
                                                <td  {...provider.dragHandleProps}
                                                     scope="row">{lectureSection.position}</td>
                                                <td  {...provider.dragHandleProps}>{lectureSection.lecture.name}</td>
                                                <td  {...provider.dragHandleProps}>{lectureSection.lecture.teacher.name}</td>
                                                <td>
                                                    <SectionLectureMenu
                                                        courseId={courseId}
                                                        moduleId={moduleId}
                                                        sectionId={sectionId}
                                                        lecture={lectureSection.lecture}
                                                        onDelete={handleRemoveLecture}/>
                                                </td>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                </tbody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext> : <Alert sx={{width: 1, mx: 'auto'}}>Nenhuma aula adicionada.</Alert>}
            </Stack>
        </Sheet>
    )
}