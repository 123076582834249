import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import './core/axios-config'
import App from "./App";
import {StyledEngineProvider} from '@mui/joy/styles';
import dayjs from "dayjs";
import Loading from "./components/Loading";
import * as Sentry from "@sentry/react";
import ErrorPage from "./components/ErrorPage";
import LectureRegistrationPage from "./pages/admin/registration/lecture/LectureRegistrationPage";
import LectureRegistrationDetail from "./pages/admin/registration/lecture/LectureRegistrationDetail";
import CourseRegistrationPage from "./pages/admin/registration/course/CourseRegistrationPage";
import CourseEditPage from "./pages/admin/registration/course/CourseEditPage";
import ModuleEditPage from "./pages/admin/registration/course/module/ModuleEditPage";
import InstitutionPage from "./pages/admin/institution/InstitutionPage";

require('dayjs/locale/pt-br')
dayjs.locale('pt-br')

//Auth
const Auth = React.lazy(() => import("./pages/auth/Auth"));
const Login = React.lazy(() => import("./pages/auth/Login"));
const ForgetPassword = React.lazy(() => import( "./pages/auth/ForgetPassword"));
const Signup = React.lazy(() => import( "./pages/auth/Signup"));
const ForgetPasswordForm = React.lazy(() => import( "./pages/auth/ForgetPasswordForm"));
const CodeSent = React.lazy(() => import( "./pages/auth/CodeSent"));

//Admin
const Admin = React.lazy(() => import( "./pages/admin/Admin"));
const Users = React.lazy(() => import( "./pages/admin/users/Users"));
const Profile = React.lazy(() => import( "./pages/admin/profile/ProfilePage"));
const Dashboard = React.lazy(() => import( "./pages/admin/Dashboard"));
const SuggestionsPage = React.lazy(() => import( "./pages/admin/suggestions/SuggestionsPage"));
const EssayListPage = React.lazy(() => import( "./pages/admin/essay-view/EssayListPage"));
const QuestionListPage = React.lazy(() => import( "./pages/admin/question-view/QuestionListPage"));
const ExamsPage = React.lazy(() => import( "./pages/admin/registration/exam/ExamsPage"));
const ExamDetail = React.lazy(() => import( "./pages/admin/registration/exam/ExamDetail"));
const QuestionTablePage = React.lazy(() => import( "./pages/admin/registration/question/QuestionTablePage"));
const QuestionDetail = React.lazy(() => import( "./pages/admin/registration/question/QuestionDetail"));
const CommentedAnswerDetail = React.lazy(() => import( "./pages/admin/registration/question/CommentedAnswerDetail"));
const DomainPage = React.lazy(() => import( "./components/domain/DomainPage"));
const CommentsPage = React.lazy(() => import( "./pages/admin/comments/CommentsPage"));
const CourseListPage = React.lazy(() => import( "./pages/admin/courses/CourseListPage"));
const MyCourses = React.lazy(() => import( "./pages/admin/my-courses/MyCoursesPage"));
const CoursesByInstitutionPage = React.lazy(() => import( "./pages/admin/institutions/CoursesByInstitutionPage"));
const LecturePage = React.lazy(() => import( "./pages/admin/lectures/LecturePage"));
const CourseStatementPage = React.lazy(() => import( "./pages/admin/lectures/CourseStatementPage"));
const UserSubscriptionPage = React.lazy(() => import( "./pages/admin/users/user-subscription/UserSubscriptionPage"));
const UserCoursePage = React.lazy(() => import( "./pages/admin/users/user-course/UserCoursePage"));

const router = createBrowserRouter([
    {
        path: "/",
        element: <Suspense fallback={<Loading/>}><App/></Suspense>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "",
                element: <Suspense fallback={<Loading/>}> <Admin/></Suspense>,
                children: [
                    {
                        path: "",
                        element: <Suspense fallback={<Loading/>}> <Dashboard/></Suspense>,
                    },
                    {
                        path: "courses-institution",
                        element: <Suspense fallback={<Loading/>}> <CoursesByInstitutionPage/> </Suspense>
                    },
                    {
                        path: "course",
                        element: <Suspense fallback={<Loading/>}> <CourseListPage/></Suspense>,
                    },
                    {
                        path: "course/:courseId",
                        element: <Suspense fallback={<Loading/>}> <CourseStatementPage/></Suspense>,
                    },
                    {
                        path: "course/:courseId/lecture",
                        element: <Suspense fallback={<Loading/>}> <LecturePage/></Suspense>,
                    },
                    {
                        path: "my-courses",
                        element: <Suspense fallback={<Loading/>}> <MyCourses/></Suspense>,
                    },
                    {
                        path: "institution/:institutionId",
                        element: <Suspense fallback={<Loading/>}> <InstitutionPage/></Suspense>,
                    },
                    {
                        path: "question-view",
                        element: <Suspense fallback={<Loading/>}> <QuestionListPage/></Suspense>,
                    },
                    {
                        path: "essay-view",
                        element: <Suspense fallback={<Loading/>}> <EssayListPage/></Suspense>,
                    },
                    {
                        path: "users",
                        element: <Suspense fallback={<Loading/>}> <Users/></Suspense>,
                    },
                    {
                        path: 'users/:userId/subscription',
                        element: <Suspense fallback={<Loading/>}><UserSubscriptionPage/></Suspense>
                    },
                    {
                        path: 'users/:userId/course',
                        element: <Suspense fallback={<Loading/>}><UserCoursePage/></Suspense>
                    },
                    {
                        path: "profile",
                        element: <Suspense fallback={<Loading/>}> <Profile/></Suspense>,
                    },
                    {
                        path: "settings/:domain",
                        element: <Suspense fallback={<Loading/>}> <DomainPage/></Suspense>,
                    },
                    {
                        path: 'registration',
                        children: [
                            {
                                path: "question",
                                element: <Suspense fallback={<Loading/>}> <QuestionTablePage/></Suspense>,
                            },
                            {
                                path: "question/:id",
                                element: <Suspense fallback={<Loading/>}> <QuestionDetail/></Suspense>,
                            },
                            {
                                path: "question/:id/commented-answer",
                                element: <Suspense fallback={<Loading/>}> <CommentedAnswerDetail/></Suspense>,
                            },
                            {
                                path: "exam",
                                element: <Suspense fallback={<Loading/>}> <ExamsPage/></Suspense>,
                            },
                            {
                                path: "exam/:id",
                                element: <Suspense fallback={<Loading/>}> <ExamDetail/></Suspense>,
                            },
                            {
                                path: 'lecture',
                                element: <Suspense fallback={<Loading/>}> <LectureRegistrationPage/></Suspense>
                            },
                            {
                                path: 'lecture/:id',
                                element: <Suspense fallback={<Loading/>}> <LectureRegistrationDetail/></Suspense>
                            },
                            {
                                path: 'course/',
                                element: <Suspense fallback={<Loading/>}> <CourseRegistrationPage/></Suspense>
                            },
                            {
                                path: 'course/edit/:id',
                                element: <Suspense fallback={<Loading/>}> <CourseEditPage/></Suspense>
                            },
                            {
                                path: 'course/edit/:courseId/module/:moduleId',
                                element: <Suspense fallback={<Loading/>}> <ModuleEditPage/></Suspense>
                            }
                        ]
                    },
                    {
                        path: "suggestion",
                        element: <Suspense fallback={<Loading/>}> <SuggestionsPage/></Suspense>,
                    },
                    {
                        path: 'comment',
                        element: <Suspense fallback={<Loading/>}> <CommentsPage/></Suspense>
                    }
                ]
            },
            {
                path: "",
                element: <Suspense fallback={<div>Carregando...</div>}> <Auth/></Suspense>,
                children: [
                    {
                        path: "login",
                        element: <Suspense fallback={<div>Carregando...</div>}> <Login/></Suspense>,
                    },
                    {
                        path: "signup",
                        element: <Suspense fallback={<div>Carregando...</div>}> <Signup/></Suspense>,
                    },
                    {
                        path: "forget-password",
                        element: <Suspense fallback={<div>Carregando...</div>}> <ForgetPassword/></Suspense>,
                    },
                    {
                        path: "forget-password/:code",
                        element: <Suspense fallback={<div>Carregando...</div>}> <ForgetPasswordForm/></Suspense>,
                    },
                    {
                        path: "code-sent",
                        element: <Suspense fallback={<div>Carregando...</div>}> <CodeSent/></Suspense>,
                    }
                ]
            }
        ]
    },
]);

Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: "https://c9c3237ed9acc075ba6d46e0cc6be9f6@o4506396229107712.ingest.sentry.io/4506507404247040",
    enabled: process.env.REACT_APP_ENABLE_SENTRY,
    integrations: [
        Sentry.browserTracingIntegration({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [process.env.REACT_APP_TRACING_TARGET],
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <RouterProvider router={router}/>
        </StyledEngineProvider>
    </React.StrictMode>
);


