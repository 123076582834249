import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {useState} from "react";
import * as React from "react";
import LectureRemoveModal from "./LectureRemoveModal";
import LectureMenu from "./LectureMenu";
import dayjs from "dayjs";
import {AspectRatio} from "@mui/joy";

export default function LectureRow({lecture}) {
    const [remove, setremove] = useState(false);

    return (
        <>
            <LectureRemoveModal lecture={lecture}
                                open={remove}
                                onClose={() => setremove(false)}></LectureRemoveModal>
            <tr>
                <td style={{paddingLeft: '16px'}}>
                    <Typography level="body-xs">{lecture.id}</Typography>
                </td>
                <td style={{paddingLeft: '16px'}}>
                    {lecture.video?.thumbnail ?
                        <AspectRatio objectFit="contain">
                            <img
                                src={lecture.video.thumbnail}
                                alt="thumbnail"
                            />
                        </AspectRatio> : undefined}
                </td>
                <td style={{padding: '12px 16px'}}>
                    <Typography level="body-xs">{lecture.name}</Typography>
                </td>
                <td style={{padding: '12px 16px'}}>
                    <Typography level="body-xs">{lecture.teacher.name}</Typography>
                </td>
                <td style={{padding: '12px 12px 12px 16px'}}>
                    <Typography level="body-xs">{lecture.discipline?.name}</Typography>

                </td>
                <td style={{padding: '12px 16px'}}>
                    <Typography level="body-xs">{lecture.subjects?.map(s => s.name).join(', ')}</Typography>
                </td>
                <td>
                    <Typography
                        level="body-xs">{dayjs(lecture.createdAt).format('D MMM YYYY HH:mm:ss')}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <LectureMenu lecture={lecture} onDelete={() => setremove(true)}/>
                    </Box>
                </td>
            </tr>
        </>
    )
}
